//@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* { 
  scroll-margin: 74px;
}

body {
  margin: 0;
  //font-family: 'Space Grotesk', sans-serif;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #242424;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.border-bottom-gray {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.border-bottom-gray-full {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}

.border-side-transparent {
  border-left: 2px solid transparent;
}

.border-bottom-transparent {
  border-bottom: 2px solid transparent;
}

.active {
  border-bottom: 2px solid #FF6B00;
  opacity: 0.99;
}

//.border-danger {
//
//}

.active-border-side {
  border-left: 2px solid #FF6B00;
  opacity: 0.99;
}

.active-border-bottom {
  border-bottom: 2px solid #FF6B00;
  opacity: 0.99;
}

.report-name:hover {
  color: #FF6B00;
  border-bottom: 2px solid #FF6B00;
}

.border-darker {
  border: 1px solid #434343;
}
